import { forwardRef } from "react";
import cn from "classnames";

import Spinner from "../spinner";

const Button: React.FC<any> = forwardRef<any, any>(
	({ loading, disabled, children, full, variant = "base", className, ...btnProps }, ref) => {
		return (
			<button
				className={cn("uppercase", className, {
					"w-full": full,
					"btn btn-secondary bg-secondary text-accent hover:bg-transparent hover:text-white": variant === "secondary",
					"btn btn-accent bg-accent text-primary hover:bg-transparent hover:text-white": variant === "accent",
					"btn btn-primary text-white hover:bg-transparent": variant === "base",
				})}
				disabled={disabled || loading}
				ref={ref}
				{...btnProps}
			>
				{loading ? <Spinner size={1} /> : children}
			</button>
		);
	}
);

Button.displayName = "Button";
export default Button;
